import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
})
export class UserCardComponent {
  @Input() data: any;
  @Input() color: any;

  @Input() sectionName: any;

  imageUrl: any; loaded: boolean = false;

  constructor() {
    this.imageUrl = environment.imageUrl;
  }
}
