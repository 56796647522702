import { Component, OnInit } from '@angular/core';
import { Network, PluginListenerHandle } from '@capacitor/core';
import { Platform, ToastController } from '@ionic/angular';
import { User } from 'src/app/auth/user';
import { CommonService, GlobalVariable } from 'src/app/services/common.service';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
})
export class ConnectionComponent implements OnInit {
  currentUser: User;
  networkStatus: any;
  networkListener: PluginListenerHandle;
  connectionMassage: string = '';
  cssConnection: string = '';

  constructor(private plt: Platform, private toastCtrl: ToastController, public commonService: CommonService) {
    this.plt.ready().then(() => {
      this.getNetWorkStatus();
    });
  }

  ngOnInit() {
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.networkStatus = status;
      this.checkNetworkConnection();
      // console.log('Network status changed', status);
    });

  }

  async getNetWorkStatus() {
    this.networkStatus = await Network.getStatus();
    // console.log(this.networkStatus);
    if (this.networkStatus.connected == false) {
      // this.connectionMassage = 'Back to online...';
      // this.cssConnection = 'ion-text-center connection-success';
      // this.setErrorMessage();
      this.commonService.showToast(GlobalVariable.NO_INTERNET_CONNECTION, 2);
    }
    //  else {
    //   this.connectionMassage = 'Sorry you are offline!';
    //   this.cssConnection = 'ion-text-center connection-error';
    //   this.setErrorMessage();
    // }
  }

  async checkNetworkConnection() {
    this.networkStatus = await Network.getStatus();
    if (this.networkStatus.connected == false) {
      this.commonService.showToast(GlobalVariable.NO_INTERNET, 2);
    } else {
      this.commonService.showToast(GlobalVariable.INTERNET, 1);
    }
  }

  setErrorMessage() {
    setTimeout(
      function () {
        var id200 = document.getElementById("id200");
        id200.style.display = "none";
      }, 3000
    );
  }
}
