import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent {
  @Input() data: any;

  constructor(private router: Router) { }

  onPage(pageName, listcount) {
    localStorage.setItem('staffSection', '/staff-section/' + pageName + '/' + listcount);
    this.router.navigateByUrl('/staff-section/' + pageName + '/' + listcount);

  }

}
