import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'check-site', canActivate: [AuthGuard], loadChildren: () => import('./pages/check-site/check-site.module').then(m => m.CheckSitePageModule) },
  { path: 'home', canActivate: [AuthGuard], loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'staff-section/:pagename/:listcount', canActivate: [AuthGuard], loadChildren: () => import('./pages/staff-section/staff-section.module').then(m => m.StaffSectionPageModule) },
  { path: '**', redirectTo: 'notfound', pathMatch: 'full' },
  { path: 'notfound', loadChildren: () => import('./pages/notfound/notfound.module').then(m => m.NotfoundPageModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})

export class AppRoutingModule { }


