import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { GlobalVariable } from '../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService) { }

  //canActivate(): boolean { return this.auth.authStateValue; }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.authStateValue) {
      return this.auth.authStateValue;
    } else {
      this.auth.logout(GlobalVariable.SESSION_EXPIRE, 2);
      return this.auth.authStateValue;
    }
  }

}
