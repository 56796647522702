import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { environment } from '../../environments/environment';


const apiUrl = environment.apiUrl;


export const GlobalVariable = Object.freeze({
  NO_INTERNET_CONNECTION: "Poor Internet Connection. Please check it and try again.",
  NO_INTERNET: "Sorry you are offline!",
  INTERNET: "Back to online...",
  NETWORK_ERROR: 'network_error',
  NO_RESULT: 'No Result Found.',
  SESSION_EXPIRE: 'Your session was expired!',
  INVALID_USER: 'The given data was invalid.',
  SERVER_ERROR: 'Sorry server is busy!',
  imageUrl: environment.imageUrl,
});

export const GlobalApi = Object.freeze({
  GET_DASHBOARD_COUNT: apiUrl + '/appDashboardCount',
  APP_DASHBOARD_DATA: apiUrl + '/appDashboardData',
  GET_SITE_DATA: apiUrl + '/staff_site?staff_id=',
  SET_SITE_DATA: apiUrl + '/appSelect_site?site_id=',
  ON_BRAKE: apiUrl + '/appOnBreak',
  ON_CHECK_OUT: apiUrl + '/appOnCheckOut',
  ON_LONG_BRAKE: apiUrl + '/appOnlongBreak',
  ON_CHECK_IN: apiUrl + '/appOnCheckIn',
  CHECK_IS_LOGIN: apiUrl + '/check_login'
})


	



@Injectable({
  providedIn: 'root'
})
export class CommonService {
channel: any; pusher: any;
token: any; tokenData: any; customHeader: any; 

  constructor(private http: HttpClient, private toastCtrl: ToastController, private loadingController: LoadingController) {
    // Pusher.logToConsole = true;
    // this.pusher = new Pusher('a78a4b2869e2492e74a3', {
    //   cluster: 'us2',
    //   encrypted: true
    // });
    // this.channel = this.pusher.subscribe('my-channel');
  }

  getPostData(method, URL, param) {
    return new Promise<any>((resolve, reject) => {
      if (method) {
        this.http.get(URL).subscribe((data) => {
          resolve(data);
        }, err => {
          reject(err);
        });
      } else {
        this.http.post(URL, param).subscribe((data) => {
          resolve(data);
        }, err => {
          reject(err);
        });
      }
    }).then(data => {
      if (data) { return data; }
    });
  }

  async showToast(message, color) {
    const colorToast: any = [{ id: 1, color: 'success' }, { id: 2, color: 'danger' }, { id: 3, color: 'warning' }, { id: 4, color: 'dark' }];

    const arr1 = colorToast.filter((d: any) => d.id === color);
    let toastColor = arr1[0].color;

    const toast = await this.toastCtrl.create({ message: message, duration: 3000, position: 'top', color: toastColor });
    toast.present();
  }

}
