import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-skeleton',
  templateUrl: './list-skeleton.component.html',
})
export class ListSkeletonComponent implements OnInit {
  @Input() skeletonCount: any;
  @Input() comeForm: any;

  itemData: any = [];

  constructor() {
    for (let i = 0; i < this.skeletonCount; i++) { this.itemData.push(i); }
  }

  ngOnInit() { }

}
