import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from './user';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CommonService, GlobalVariable } from '../services/common.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState = new BehaviorSubject(false);
  userData: any; userToken: any; state: boolean = true;

  constructor(private platform: Platform, private http: HttpClient, public router: Router, private commonService: CommonService) {
    this.platform.ready().then(() => {
      this.ifIsLoggedIn()
    });
  }

  ifIsLoggedIn() {
    this.userToken = JSON.parse(localStorage.getItem('currentUser'));
    if (this.userToken !== null) { this.authState.next(true); }
  }


  public get getToken() {
    this.userToken = JSON.parse(localStorage.getItem('currentUser'));
    return this.userToken;
  }

  public get authStateValue() { return this.authState.value; }

  login(value) {
    return this.http.post<User>(environment.apiUrl + '/login', value)
      .pipe(map(user => {
        if (user && user.token) {
          this.userData = user;
          localStorage.setItem('currentUser', JSON.stringify(user.token));
          localStorage.setItem('current_site_id', this.userData.user.current_site_id);
          localStorage.setItem('staff_id', this.userData.user.id);
          localStorage.setItem('site_name', this.userData.user.sitename);
          localStorage.setItem('_role_id', this.userData.user.role_id)
          this.authState.next(true);
        } else {
          this.logout(GlobalVariable.SESSION_EXPIRE, 2);
        }
        return user;
      }));
  }

  logout(message, color) {
    this.commonService.showToast(message, color);
    localStorage.clear();
    this.router.navigateByUrl('/login');
    this.authState.next(false);
  }
}
