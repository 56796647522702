import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectionComponent } from '../components/connection/connection.component';
import { SettingComponent } from 'src/app/components/setting/setting.component';
import { UserCardComponent } from './user-card/user-card.component';
import { ListSkeletonComponent } from './list-skeleton/list-skeleton.component';
import { DashboardCardComponent } from './dashboard-card/dashboard-card.component';
const PAGES_COMPONENTS = [ConnectionComponent, SettingComponent, UserCardComponent, ListSkeletonComponent, DashboardCardComponent];


@NgModule({
  declarations: [PAGES_COMPONENTS],
  imports: [
    CommonModule,
  ],
  exports: [PAGES_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class ComponentModule { }
