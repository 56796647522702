import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { GlobalVariable } from '../services/common.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 && err.status !== 422) {
                this.auth.logout(GlobalVariable.SESSION_EXPIRE, 2);
            }
            const error = err.error.message || err.statusText;
            if (err.status === 422 && err.status !== 401) {
                this.auth.logout(err.error.message, 2);
            }
            return throwError(error);
        }))
    }
}