export const environment = {
  production: false,
  appName: 'Break Display',

  // apiUrl : 'http://192.168.1.72:8001/api',
  // imageUrl : 'http://192.168.1.72:8001/'

  
  apiUrl: 'https://break-display.thermalcode.com/api',
  imageUrl: 'https://break-display.thermalcode.com/'

  // apiUrl: 'https://break-display-api.azurewebsites.net/api',
  // imageUrl: 'https://break-display-api.azurewebsites.net/'

};