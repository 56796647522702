import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController, } from '@ionic/angular';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
})
export class SettingComponent {

  constructor(private popoverController: PopoverController, private auth: AuthService, private router: Router) { }

  changeSite() {
    this.router.navigateByUrl('/check-site');
    this.popoverController.dismiss();
  }
  logout() {
    this.auth.logout('Successfully logout', 4);
    this.popoverController.dismiss();
  }
}
