import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthService } from './auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  ngOnInit(): void { }

  constructor(private platform: Platform, private router: Router, private auth: AuthService) {
    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.auth.authState.subscribe(state => {
        if (state == false) {
          this.router.navigate(['/login']);
        }
      });
    });
  }

}
